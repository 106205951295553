.money-from-links {
	padding-left: 0;

	&.savings-accounts {
		column-count: 2;
		column-gap: 20px;
	}
}

.money-from-links > li {
	margin: 0;
	list-style-type: none;
	border-bottom: 1px solid #c7c7c7;
}

.money-from-links > li > a {
	display: block;
	position: relative;
	padding: 15px 26px 15px 10px;
}

.money-from-links > li > a:after {
	content: "\f105";
	font-family: fontawesome;
	font-weight: bold;
	position: absolute;
	right: 10px;
	top: 50%;
	transform: translateY(-50%);
}
