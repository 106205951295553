.dashboard-callout {
	border-radius: 20px;
}

.progress {
	overflow: visible !important;
	height: 4px !important;
	position: relative;
}

.progress-bar {
	height: 14px !important;
	width: 14px !important;
	transform: translate(-50%, -50%);
	border-radius: 50%;
}

.onboarding-dashboard-links {
	padding-left: 0;
}

.onboarding-dashboard-links > li {
	margin: 0;
	list-style-type: none;
	border-bottom: 1px solid #c7c7c7;
}

.onboarding-dashboard-link {
	position: relative;
	padding: 15px 26px 15px 10px;
	width: 100%;
	display: inline-block;

	&:after {
		content: "\F105";
		font-family: fontawesome;
		font-weight: bold;
		position: absolute;
		right: 10px;
		top: 50%;
		transform: translateY(-50%);
		float: right;
	}
}
