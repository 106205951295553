.increment-decrement-btn {
	border: 3px solid #4b4687;
	border-radius: 50px;
	display: inline-block;
	height: 39px;
	width: 39px;
	position: relative;
	top: -4px;
	padding-top: 7px;
	margin: 0 3px;
}
