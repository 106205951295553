body {
	background-color: #fff !important;
	background-position: right top;
	background-size: cover;
	background-repeat: no-repeat;
}

.video-container {
	position: fixed;
	overflow: hidden;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: -2;
}

.image-background {
	position: absolute;
	display: none;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	min-width: 100%;
	min-height: 100%;
}

.img-background {
	z-index: -2;
	display: none;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	min-width: 100%;
	min-height: 100%;
}

.overlay {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: -1;
	background-color: #fff;
}

.row-flex {
	height: 100vh;
}

.button-group {
	padding-bottom: 30px;
}

/* font sizing */
h1 {
	font-size: 36px;
}

h2 {
	font-size: 28px;
}

h3 {
	font-size: 24px;
	font-family: "FS Elliot Web Bold", "FS Elliot Web Regular", "Arial", arial,
		sans-serif;
}

h4 {
	font-size: 20px;
}

h5 {
	font-size: 15px;
}

h6 {
	font-size: 14px;
}

p {
	&.xl {
		font-size: 24px;
	}

	&.lg {
		font-size: 16px;
	}

	&.md {
		font-size: 13px;
	}

	&.sm {
		font-size: 10px;
	}
}

@media (min-width: 480px) {
	h1 {
		font-size: 42px;
	}
	h2 {
		font-size: 30px;
	}
	h3 {
		font-size: 24px;
		font-family: "FS Elliot Web Bold", "FS Elliot Web Regular", "Arial",
			arial, sans-serif;
	}
	h4 {
		font-size: 20px;
	}
	h5 {
		font-size: 15px;
	}
	p.xl {
		font-size: 20px;
	}
	p.lg {
		font-size: 15px;
	}
	p.md {
		font-size: 13px;
	}
	p.sm {
		font-size: 10px;
	}
	.img-background {
		display: block;
	}
	.overlay {
		background-color: rgba(255, 255, 255, 0.9);
		right: 50%;
	}
}

@media (min-width: 768px) {
	h1 {
		font-size: 42px;
	}
	h2 {
		font-size: 30px;
	}
	h3 {
		font-size: 24px;
		font-family: "FS Elliot Web Bold", "FS Elliot Web Regular", "Arial",
			arial, sans-serif;
	}
	h4 {
		font-size: 20px;
	}
	h5 {
		font-size: 15px;
	}
	p.xl {
		font-size: 20px;
	}
	p.lg {
		font-size: 15px;
	}
	p.md {
		font-size: 13px;
	}
	p.sm {
		font-size: 10px;
	}
	.image-background {
		display: block;
	}
	.button-group {
		padding-bottom: 200px;
	}
}

/* btn overrides */

.btn.btn-primary {
	border-radius: 30px;
	padding-left: 30px;
	padding-right: 30px;
	box-shadow: inset 0 -2px 0 0 #0076cf;
	border: none !important;
}

.btn.btn-primary:hover {
	background-color: #0166b2;
	box-shadow: inset 0 -2px 0 0 #0166b2;
}

.btn.btn-primary:focus {
	background-color: #0166b2;
	box-shadow: inset 0 0 5px 0 #003258;
}

.btn.btn-primary.disabled:focus {
	background-color: #0166b2;
	color: #8c8d8e;
	box-shadow: inset 0 0 5px 0 #003258;
}

.btn.btn-default {
	background-color: #fff;
	border-radius: 30px;
	padding-left: 30px;
	padding-right: 30px;
	box-shadow: inset 0 -2px 0 0 #e6e6e6;
	border-color: #e6e6e6;
}

.btn.btn-default:hover {
	color: #fff;
	background-color: #0091da;
	box-shadow: inset 0 -2px 0 0 #0091da;
	border-color: #0091da;
}

.btn.btn-default:focus {
	color: #fff;
	background-color: #0166b2;
	box-shadow: inset 0 0 5px 0 #003258;
	border-color: #003258;
}

.btn.btn-default.disabled:focus {
	background-color: #0166b2;
	color: #8c8d8e;
	box-shadow: inset 0 0 5px 0 #003258;
	border-color: #003258;
}

.flex-bottom {
	margin-top: auto;
}

.col-flex {
	display: flex;
	flex-flow: column;
	height: 100vh;
}

.principal-logo {
	max-width: 100%;
}

.flex-horizontal {
	display: flex;
	flex-direction: horizontal;
}

.flex-vertical {
	display: flex;
	flex-direction: column;

	> button:not(:first-child) {
		margin-top: 10px;
	}
}
