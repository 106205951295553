.panel {
	border-radius: 8px;
	border: 0px;
	background-color: #f8f8f8;
	box-shadow: 0 2px 0 0 rgba(222, 222, 222, 0.5);
}

.panel-default .panel-heading {
	background-color: #0076cf !important;
	color: #fff !important;
	border-radius: 6px 6px 0 0;
}

.modal-text {
	color: #464646 !important;
}

.btn.btn-white {
	background-color: #fff !important;
	border-radius: 30px !important;
	padding-left: 30px !important;
	padding-right: 30px !important;
	box-shadow: inset 0 -2px 0 0 #e6e6e6 !important;
	color: #4b4687 !important;
	border: none !important;
}
