.modal-fade {
	display: block;
	animation-name: modalFade;
	animation-duration: 0.5s;
}

@keyframes modalFade {
	from {
		transform: translateY(-50%);
		opacity: 0;
	}
	to {
		transform: translateY(0);
		opacity: 1;
	}
}

.modal-background {
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 11;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}

@media only screen and (min-width: 768px) {
	.modal-dialog {
		width: 100%;
	}
}
