// common styles
.text-white {
	color: #fff;
}

.md-text-right {
	@media (min-width: 768px) {
		text-align: right;
	}
}

.principal-logo-image-white {
	height: 50px;
	width: 150px;
}

.logout {
	font-size: 14px;
	min-width: 350px;
	margin-left: 70%;
	@media only screen and (max-width: 1200px) {
		margin-left: 63%;
	}
}

.sample-company {
	height: 55px;
}

.plan-info-section {
	font-size: 15px;
}

// different styles for tooltips
.link-font {
	font-family: "FS Elliot Web Bold", "FS Elliot Web Regular", "Arial", arial,
		sans-serif;
}

.nav-inline li:first-child .footer-link {
	border-left: 0;
}

.footer-link {
	@extend .link-font;
	color: #787878;
	border-left: 2px solid #c7c7c7;
	padding: 0px 15px;
}

.container-tab {
	bottom: 0px;
	right: 15px;
	position: fixed;
	border-radius: 4px;
	z-index: 2;

	@media only screen and (min-width: 768px) {
		width: 40%;
	}

	@media only screen and (min-width: 1200px) {
		width: 30%;
	}
}

.chat-border {
	border: 2px solid #c7c7c7;
	border-radius: 16px;
}

.chat-wrapper {
	width: 90%;
}