.radio.styled-radio-btn {
	margin: 0;
}

.radio.styled-radio-btn input[type="radio"]:not(.old) {
	position: absolute;
	width: 1px;
	height: 1px;
	margin: -1px;
	padding: 0;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	border: 3px solid #999;
}

.radio.styled-radio-btn:first-of-type label {
	border-radius: 6px 6px 0 0;
}

.radio.styled-radio-btn label {
	position: relative;
	padding: 15px;
	padding-left: 55px;
	display: block;
	height: 100%;
	width: 100%;
	border: 3px solid #e6e7e8;
	background-color: #fff;
	margin-bottom: -3px;
}

.radio.styled-radio-btn input[type="radio"]:not(.old):checked + label:before {
	border-color: #0091da;
	border-width: 3px;
}

.radio.styled-radio-btn label:before {
	content: "";
	display: inline-block;
	position: absolute;
	width: 24px;
	height: 24px;
	left: 0;
	margin-left: 15px;
	top: 50%;
	transform: translateY(-50%);
	border: 2px solid #e6e7e8;
	border-radius: 50%;
	background-color: #fff;
}

.radio.styled-radio-btn input[type="radio"]:not(.old):checked + label:after {
	display: inline-block;
}

.radio.styled-radio-btn label:after {
	display: none;
	position: absolute;
	content: " ";
	width: 12px;
	height: 12px;
	left: 21px;
	border-radius: 50%;
	background-color: #0091da;
	top: calc(50% - 6px);
}

.js-close-expanded {
	display: block;
	font-size: 30px;
	position: absolute;
	right: 30px;
	top: 75px;
	border: none;
	background: transparent;

	@media only screen and (max-width: 768px) {
		top: 45px;
	}
}

.right-bar {
	transition: all 0.5s;
	transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
	padding-top: 60px !important;
}
