.before-content {
	content: " ";
	position: absolute;
	border: 10px solid;
	left: -10px;
	top: 0;
}

.conversation-left-top:before {
	@extend .before-content;
	border-color: #00c19f transparent transparent transparent;
}

.conversation-right-top:before {
	@extend .before-content;
	border-color: #ffffff transparent transparent transparent;
}

.conversation-content {
	color: #ffffff;
	background-color: #00c19f;
	padding: 8px 15px;
	margin-right: 15px;
	border-radius: 10px;
	font-size: 18px;
	position: relative;
	clear: both;
}

.conversation-answer {
	display: block;
	width: 100%;
	text-align: center;
	float: left;
	margin-bottom: 10px;
	margin-top: 5px;
}

.conversation-right {
	margin-left: 15px;
	background-color: #ffffff;
	float: right;
	color: #464646;
	margin-bottom: 5px;
}

.conversation-left {
	float: left;
	margin-bottom: 5px;
}

.d-flex-row {
	display: flex;
	flex-direction: row;
}

.d-flex-col {
	display: flex;
	flex-direction: column;
	clear: both;

	& > .conversation-content {
		margin-bottom: 0;
		border-radius: 10px 10px 0 0;
	}
}

.h-100 {
	height: 100px;
}

.conversation-panel {
	background-color: #ffffff;
	color: #464646;
	border: 1px solid #00c19f;
	border-radius: 0 0 10px 10px;
	margin-right: 15px;
	margin-bottom: 5px;
}

.chat-window {
	overflow-y: scroll;
	height: calc(100vh - 180px);
	scroll-behavior: smooth;
}

.conversation-content-back {
	float: left;
	width: 100%;
	margin-top: 20px;
	clear: both;
}

.blue-bg {
	background-color: #0076cf;
}
