.nodemo {
	position: relative;

	&-trigger {
		display: inline-block;
	}

	&-tooltip {
		min-width: 165px;
		position: absolute;
		z-index: 10;

		&::after {
			content: "";
			position: absolute;
		}
	}
}

.tooltip {

	&-top {
		left: 50%;
		top: -435%;
		transform: translateX(-50%);

		&::after {
			border-left: 9px solid transparent;
			border-right: 9px solid transparent;
			border-top: 9px solid #666666;
			left: 50%;
			transform: translateX(-50%);
		}
	}

	&-bottom {
		top: 260%;
		left: 50%;
		transform: translateX(-50%);

		&::after {
			border-left: 9px solid transparent;
			border-right: 9px solid transparent;
			border-bottom: 9px solid #666666;
			top: -18%;
			left: 50%;
			bottom: 100%;
			transform: translateX(-50%);
		}
	}

	&-left {
		top: 50%;
		right: 100%;
		padding-right: 9px;
		transform: translateY(-50%);

		&::after {
			border-left: 9px solid #666666;
			border-top: 9px solid transparent;
			border-bottom: 9px solid transparent;
			top: 50%;
			right: 0;
			transform: translateY(-50%);
		}
	}

	&-right {
		top: 50%;
		left: 100%;
		padding-left: 9px;
		transform: translateY(-50%);

		&::after {
			border-right: 9px solid #666666;
			border-top: 9px solid transparent;
			border-bottom: 9px solid transparent;
			top: 50%;
			left: 0;
			transform: translateY(-50%);
		}
	}

	&-message {
		background: #666666;
		border-radius: 7px;
		color: #ffffff;
		font-size: 13px;
		padding: 10px;
		text-align: center;
	}
}
