.triangle {
	width: 0;
	border: {
		left: 15px solid transparent;
		right: 15px solid transparent;
		bottom: 30px solid #e5f9f5;
	}
	margin-left: 73.23%;
}

.util-border-radius-20 {
	border-radius: 20px;
}