.client-logo {
	width: inherit;
}

.mobile-phone-add-acct {
	width: 70%;
	display: block;
	margin: auto;
}

#sidebar {
//  causing box to be too tall on rollover pages
//	min-height: 75em;
	padding-top: 60px !important;
}