$slider-box-size: 46px;
$color-gray: #c7c7c7;
$color-medium-light-gray: #e6e6e6;
$color-light-blue: #e5f4fb;
$brand-primary: #0091da;

@mixin font-regular {
	font-family: "FS Elliot Web Regular", "FS Elliot Web Regular", "Arial",
		arial, sans-serif;
	font-weight: normal;
	font-style: normal;
}

.slider-wrapper {
	border: 1px solid $color-gray;
	border-radius: 3px;
	padding: 5px 40px;
	height: 44px;
	position: relative;

	.slider {
		position: relative;
		width: 92%;
		margin: 0 auto;
	}
}

.slider-button {
	background-color: $color-light-blue;
	border: 0;
	color: $brand-primary;
	@include font-regular();
	height: 100%;
	position: absolute;
	top: 0;
	width: 38px;

	&.highlight {
		background-color: $brand-primary;
		color: #f0f2f4;
	}

	&.decrement {
		border-radius: 3px 0 0 3px;
		border-right: 1px solid $color-gray;
		left: 0;
	}

	&.increment {
		border-left: 1px solid $color-gray;
		border-radius: 0 3px 3px 0;
		right: 0;
	}

	&.disabled {
		color: #a9a9a9;
		background-color: #ddd;
	}

	&:active {
		background-color: #ddd;
		box-shadow: inset 0 2px 2px #ccc;
	}

	&:hover,
	&:focus {
		background-color: darken($color-light-blue, 5%);
	}
}

.slider-marker {
	position: absolute;
	display: block;
	margin-bottom: 0;
	margin-left: -5px;
	text-align: left;
	bottom: -25px;
	cursor: pointer;
	font-size: 11px;
	@include font-regular;

	&:before {
		content: "";
		height: 22px;
		width: 1px;
		border-left: 1px solid #5aaa85 !important;
		position: absolute;
		bottom: 20px;
		left: 5px;
	}

	&:after {
		content: "";
		width: 11px;
		height: 11px;
		border-radius: 50%;
		background: #5aaa85;
		position: absolute;
		bottom: 34px;
		left: 0;
	}

	&[data-position="left"] {
		text-align: right;
		margin-right: -5px;
		margin-left: 0;
		padding-right: 4px;

		&:before {
			left: auto;
			right: 5px;
		}

		&:after {
			left: auto;
			right: 0;
		}
	}

	&[data-position="right"] {
		padding-left: 4px;
	}
}

.slider-marker-match {
	margin-bottom: -15px;
}

.slider-value {
	color: white;
	display: block;
	font-size: 24px;
	text-align: center;
	width: 100%;
	padding-top: 7px;
	@include font-regular();

	small {
		display: inline;
		font-size: 14px;
		padding: 0;
		position: relative;
		top: 1px;
		vertical-align: text-top;

		span {
			visibility: hidden;
			top: 13px;
			left: -3px;
			position: absolute;

			&:before {
				content: ".";
			}
		}
	}

	&:after {
		content: "";
		display: block;
		position: absolute;
		left: 50%;
		margin-left: -6px;
		bottom: 0;
		border: 6px solid transparent;
		border-bottom-color: $color-medium-light-gray;
	}
}

.noUi-horizontal {
	height: 100%;
}

.noUi-horizontal .noUi-handle {
	position: absolute;
	width: $slider-box-size;
	height: $slider-box-size;
	left: -$slider-box-size / 2;
	top: -15px;
}

.noUi-horizontal.noUi-extended {
	padding: 0 15px;
}

.noUi-horizontal.noUi-extended .noUi-origin {
	right: -15px;
}

.noUi-base {
	width: 100%;
	height: 100%;
	position: relative;
}

.noUi-background {
	background: $color-medium-light-gray repeat-x center left;
	box-shadow: none;
}

.noUi-connect {
	background: #3fb8af;
	box-shadow: inset 0 0 3px rgba(51, 51, 51, 0.45);
}

.noUi-origin {
	border-radius: 0;
	position: absolute;
	left: 0;
	z-index: 2;

	.noUi-state-tap & {
		transition: left 0.2s;
	}
}

.noUi-target {
	border-radius: 0;
	border: 0;
	box-shadow: none;
}

.noUi-target.noUi-connect {
	box-shadow: none;
}

/* Handles and cursors; */
.noUi-dragable {
	cursor: w-resize;
}

.noUi-vertical .noUi-dragable {
	cursor: n-resize;
}

.noUi-handle {
	border: 0px solid #d9d9d9;
	border-radius: 3px;
	background: $brand-primary;
	cursor: ew-resize;
	box-shadow: none;
}

.noUi-active {
	box-shadow: none;
}

/* Handle stripes; */
.noUi-handle:before,
.noUi-handle:after {
	content: "";
	display: none;
}

.noUi-handle:after {
	left: 17px;
}

/* Disabled state; */
[disabled].noUi-connect,
[disabled] .noUi-connect {
	background: #b8b8b8;
}

[disabled] .noUi-handle {
	cursor: not-allowed;
}
